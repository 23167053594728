<template>
  <nav class="orders-nav">
        <router-link
            class="orders-nav__item"
            v-for="route of routes"
            :key="route.text"
            :to="route.to"
        >{{ route.text }}</router-link>
    </nav>
</template>
<script lang="ts">
import { StoreNavItemI } from '@/stores'; // eslint-disable-line
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        routes: Array as () => StoreNavItemI[],
    }
})
</script>
<style scoped>
.orders-nav {
    height: 65px;
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
}
.orders-nav__item {
    width: 125px;
    line-height: 35px;
    border-radius: 90px;
    text-align: center;
    background-color: var(--color-mercury);
    color: var(--color-dove-gray);
    margin: 0 10px;
    text-decoration: none;
    font-size: 16px;
    font-weight: var(--weight-bold);
}
.orders-nav__item.router-link-active {
    color: var(--color-white);
    background-color: var(--color-black);
}
</style>
