<template>
    <OrdersNav :routes="routes"/>
    <router-view/>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';

import OrdersNav from '@/components/OrdersNav/OrdersNav.vue';
import { Store } from '@/stores'; // eslint-disable-line

export default defineComponent({
    name: 'Orders',
    components: { OrdersNav },
    setup() {
        const store = inject('store') as Store;
        return { routes: store.state.ordersNav }
    }
});
</script>
<style scoped>
</style>
